
/* FontAwesome 5 */
// @import "vendor/fontawesome/fontawesome.scss"; // FontAwesome 5 Base style - Required
// @import "vendor/fontawesome/regular.scss";     // FontAwesome 5 Regular icons - Optional
// @import "vendor/fontawesome/solid.scss";       // FontAwesome 5 Solid icons - Optional
// @import "vendor/fontawesome/brands.scss";      // FontAwesome 5 Brand icons - Optional


@import "vendor/owl.carousel";
@import "vendor/owl.theme.default";



/* HTML5 Boilerplate header */
@import "vendor/h5bp_header";

/* Custom project files */
@import "variables";
@import "mixins";
@import "helpers";
@import "custom";
@import "components";
@import "fonts";

/* HTML5 Boilerplate footer */
@import "vendor/h5bp_footer";
