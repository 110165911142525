.color {
    &--1 {
      color: $color-1 !important;
    }

    &--2 {
      color: $color-2 !important;
    }

    &--3 {
      color: $color-3 !important;
    }

    &--4 {
      color: $color-4 !important;
    }

    &--5 {
      color: $color-5 !important;
    }
}

