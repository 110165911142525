@mixin defaultButton($fontFamily: $defaultFontFamily, $fontSize: $defaultFontSize, $font-weight: 700, $marginX: 0, $marginY: 0, $paddingX: 25px, $paddingY: 8px) {
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  display: inline-block;
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $font-weight;
  margin: $marginY $marginX;
  padding-bottom: $paddingY;
  padding-left: $paddingX;
  padding-right: $paddingX;
  padding-top: $paddingY;
  position: relative;
  text-align: center;
  text-decoration: none !important;
}

@mixin buttonColor($color-5: $wColor, $backgroundColor: $color-2, $borderColor: $color-2, $emTextColor: $wColor, $emBackgroundColor: $color-2, $emBorderColor: $color-2) {
  background-color: $backgroundColor;
  border-color: $borderColor;
  color: $color-5;

  &:hover,
  &:focus {
    background-color: $emBackgroundColor;
    border-color: $emBorderColor;
    color: $emTextColor;
  }

  &:visited {
    color: $color-5;
  }

  &:not([href]):not([tabindex]) {
    color: $color-5;
  }

  &:not([href]):not([tabindex]):focus,
  &:not([href]):not([tabindex]):hover {
    color: $emTextColor;
  }
}

@mixin iconRoundButton() {
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  height: 40px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 0;
  vertical-align: middle;
  width: 40px;
  padding-top: 2px;

  @media (min-width: 768px) {
    height: 48px;
    width: 48px;
  }
}
