/*! custom.css - Project style entry point */

/* ==========================================================================
   Author's custom styles - Follow BEM and Components CSS file used for helpers
   ========================================================================== */

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  width: 100%;
}

body {
  background-color: $wColor;
  color: $color-5;
  font-family: $defaultFontFamily;
  font-size: $defaultFontSize;
  overflow-x: hidden;
}

h1,
h2,
h3,
strong {
  font-family: $titleFontFamily;
  font-weight: 100;
}

h4 {
  font-weight: 100;
}

img,
video {
  width: 100%;
}

a,
a:hover,
a:visited,
a:focus {
  color: inherit;
}

a:focus {
  outline: none;
}

*:focus {
  outline: none;
}

hr {
  border-top: 1px solid $color-2;
}

/* Generic & helpers */
.filter-container {
  position: relative;
}

div.filter,
div.filter-hover {
  height: 100%;
  left:0;
  position: absolute;
  top: 0;
  width: 100%;

  @include defaultTransition();
}

div.filter-black-30 {
  background-color: rgba(0,0,0,.3);
}

div.filter-black-60 {
  background-color: rgba(0,0,0,.6);
}

div.filter-white-60 {
  background-color: rgba(255,255,255,.8);
}

div.filter-black-30-hover:hover {
  background-color: rgba(0,0,0,.05);

  @include defaultTransition();
}

div.filter-white-60-hover:hover {
  background-color: rgba(255,255,255,.1);

  @include defaultTransition();
}

.menu-button {
  @include iconRoundButton();
  @include buttonColor($color-5: $color-1, $backgroundColor: transparent, $borderColor: $color-1, $emBackgroundColor: $color-1, $emBorderColor: $color-1);

  @include defaultTransition();
}

.default-button {
  @include defaultButton();
  @include buttonColor();

  @include defaultTransition();
}

.contact-button {
  @include defaultButton($fontFamily: $defaultFontFamily, $fontSize: $defaultFontSize, $font-weight: 600, $marginX: 0, $marginY: 0, $paddingX: 10px, $paddingY: 4px);
  @include buttonColor($color-5: $color-1, $backgroundColor: transparent, $borderColor: $color-1, $emBackgroundColor: $color-1, $emBorderColor: $color-1);

  @include defaultTransition();
}
.scroll-link {
  cursor: pointer;
}

.default_button {
  @include defaultButton();
  @include buttonColor($color-5: $wColor, $backgroundColor: $color-3, $borderColor: $color-3, $emTextColor: $wColor);

  @include defaultTransition();
}

.fas {
  line-height: 0;
}

.icon {
  height: 15px;
  width: 15px;

  @include defaultTransition();

  &--small {
    height: 24px;
    width: 24px;
  }

  &__rotate {
    -o-transform:rotate(180deg);
    -ms-transform:rotate(180deg);
    -moz-transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
    transform:rotate(180deg);
  }

  &--menu {
    height: 30px;
    width: 20px;
  }

  &--scrol-down {
    height: 30px;
    @include md {
      height: 40px;
    }
  }

  &--linkedin {
    height: 30px;
    width: 20px;
    margin-right: 0px !important;
  }
}

/*** Common ***/

/* Main content */
.main-content {
  height:100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

/* Generic page */

/* Component override */
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: $color-2;
}
