.pricing {
  background-color: $color-2;

  h2 {
    font-size: $largeFontSize;
    margin-bottom: $defaultMarginY;
    @include md {
      font-size: $xLargeFontSize;
      margin-bottom: $defaultMarginY * 2;
    }
    @include withBorderBottomCenter();

  }

  &__button {
    @include defaultButton($marginY: $defaultMarginY);
    @include buttonColor($color-5: $wColor, $backgroundColor: $color-3, $borderColor: $color-3, $emBackgroundColor: $color-1);

    @include defaultTransition();
  }
  &__content {
    font-size: $normalFontSize;
    margin-left: $defaultMarginX;
    margin-top: $mediumMarginY;
    margin-bottom: $mediumMarginY;
  }

  &__column {
    float: left;
    width: 100%;
    margin-bottom: 20px;
  }

  &__header {
    text-align: center;
    background-color: $color-1;
    padding: 8px;
    margin-bottom: 0;
    h3 {
      color: white;
    }
  }

  &__option {
    background-color: $wColor;
    color: $color-1;
    list-style-type: none;
    margin: 0;
    padding: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;

    li {
      padding: 20px;
    }

    em {
      font-size: $smallFontSize;
      padding-bottom: $defaultPaddingY;
      padding-left: $defaultPaddingX;
    }

    &--margin-top {
      margin-top: $defaultMarginY;
    }

    &--border {
      @include md {
        border-right:  1px solid $color-3;
        border-bottom: none;
        margin-bottom: 1rem;
      }
    }

    &--border-last {
      @include md {
        border-right:  1px solid transparent;
        border-bottom: none;
        margin-bottom: 1rem;
      }
    }

    &--item {
      border-bottom: 1px solid #eee;
    }
    svg {
      font-weight: 900;
      font-size: 0.8em;
      margin-right: 10px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      color: $color-3;
    }

    &--price {
      padding: 20px;
      list-style-type: none;

      li {
        border-bottom: none;
        padding: 0;
      }
    }

    &--header {
      text-align: center;
      background-color: $color-1;
      h3 {
        color: white;
      }

      h4 {
        color: red;
      }
    }
    &--header2 {
      text-align: center;
      border-bottom: 1px solid #eee;
      @include md {
        border-bottom: none;
      }
      h4 {
        color: $color-2 !important;
      }
    }
  }
  &__option:hover {
    box-shadow: 0 8px 12px 0 rgba(255,255,255,0.5);
  }
}
.pricing-tarifs .pricing__option--item:last-child {
  border-bottom: 1px solid $color-3;
  @include md {
    border-bottom: none;
  }
}
