$defaultFontFamily: "Poppins", sans-serif;
$titleFontFamily: "Audiowide", sans-serif;

$defaultFontSize: 16px;
$em: $defaultFontSize * 1em;

$normalFontSize: 1em;
$smallFontSize: 0.75em;       //12px
$mediumFontSize: 1.375em;     //22px
$largeFontSize: 1.5625em;     //25px
$xLargeFontSize: 2em;         //32px
$bigFontSize: 3em;            //48px

$smallPlusFontSize: $xLargeFontSize / 2;
$mainMenuFontSize: 1em;       //16px
