.offcanvas {
  height: 100%;
  left: -320px;
  position: relative;
  width: #{calc(100% + 320px)};

  @include md {
    left: -360px;
    width: #{calc(100% + 360px)};
  }

  @include defaultTransition();

  &.active {
      left: 0;
  }
}
