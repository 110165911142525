.navbar {
  background-color: $wColor;
  background-image: none;
  border: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 60px;
  margin-bottom: 0;
  padding-bottom: 5px;
  padding-top: 5px;
  z-index: 9999;

  @include md {
    background-color: $wColor;
    height: 88px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;

    &--item {
      float: left;
    }

    &--menu-button {
      padding-top: 5px;
      width: 20%;
    }

    &--logo {
      text-align: center;
      padding-top: 5px;
      width: 60%;

      img {
        width: 160px;
      }

      @include md {
        img { width: 200px; }
      }

      @include lg {
        img { width: 200px; }
      }
    }

    &--contact-button {
      text-align: right;
      margin-right: 5px;
      @include md {
        width: 20%;
        padding-top: 5px;
      }
    }
  }
}
