.footer {
  background-color: $color-1;
  color: $wColor;
  padding-bottom: $mediumPaddingY;
  padding-top: $mediumPaddingY;

  #private-policy {
    margin-right : 10px;
  }

  h1,
  h2 {
    color: $wColor;
  }

  h1 {
    font-size: $largeFontSize;
    margin-bottom: $smallMarginY;
    @include md {
      font-size: $xLargeFontSize;
    }
  }

  h2 {
    font-size: $largeFontSize;
    margin-bottom: $defaultMarginY;
    text-align: center;
    @include md {
      font-size: $xLargeFontSize;
      margin-bottom: $defaultMarginY * 2;
    }
  }

  h3 {
    margin-left: 15px;
  }

  &__subtitle {
    text-align: center;
    margin-bottom: $defaultMarginY;
    @include md {
      margin-bottom: $defaultMarginY * 2;
    }
  }

  &__contact-infos {
    margin-left: 15px;

    svg {
      margin-right: 8px;
    }
  }

  &__copyright {
    font-size: $smallFontSize;
    font-style: italic;
    text-align: center;
  }

  &__logo {
    width: 50%;
    padding-bottom: 5px;
    padding-top: 5px;
  }
}
