.faq {
  background-color : $color-4;

  i {
    position : absolute;
    right: 10px;
    top: 16px;
  }

  .card {
    margin-bottom: 20px;

    &-header {
        padding-right: 40px;
        border-bottom : none;
        background-color: $wColor;
    }
  }
}
