@mixin imageBackgroundCover($width: 100%, $height: 100%) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: $height;
    width: $width;
    -webkit-backface-visibility: hidden;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

@mixin containerPaddingLeft($screenSize) {
    padding-left: calc(((100% - #{$screenSize}) / 2) + 15px);
}

@mixin containerPaddingRight($screenSize) {
    padding-right: calc(((100% - #{$screenSize}) / 2) + 15px);
}

/* Headers borders utils */
@mixin withBorderBottom ($borderColor: $color-4, $height: 4px, $width: 40px, $marginBottom: $defaultMarginY, $paddingBottom: $defaultPaddingY) {
  margin-bottom: $marginBottom / 2;
  padding-bottom: $paddingBottom / 2;
  position:relative;

  @media (min-width: 768px) {
      margin-bottom: $marginBottom;
      padding-bottom: $paddingBottom;
  }

  &:after {
      border-bottom: $height solid $borderColor;
      content: " ";
      position: absolute;
      bottom: 0;
      width: $width;
  }
}

@mixin withBorderTop ($borderColor: $color-2, $borderWidth: 3px, $width: 40px, $marginTop: $defaultMarginY, $paddingTop: $defaultPaddingY) {
    // Reset
    margin: 0;
    padding: 0;

    margin-top: $marginTop / 2;
    padding-top: $paddingTop / 2;
    position:relative;

    @media (min-width: 768px) {
        margin-top: $marginTop;
        padding-top: $paddingTop;
    }

    &:before {
        // Reset
        border: none;

        border-top: $borderWidth solid $borderColor;
        content: " ";
        position: absolute;
        top: 0;
        width: $width;
    }
}

@mixin withBorderTopLeft($borderColor: $color-2, $borderWidth: 3px, $width: 40px, $marginTop: 30px, $paddingTop: 30px) {
    @include withBorderTop($borderColor: $borderColor, $borderWidth: $borderWidth, $width: $width, $marginTop: $marginTop, $paddingTop: $paddingTop);

    &:before {
        left: 0;
    }
}

@mixin withBorderBottomCenter($borderColor: $color-4, $height: 4px, $width: 40px, $marginBottom: $defaultMarginY, $paddingBottom: $defaultPaddingY) {
  @include withBorderBottom($borderColor: $borderColor, $height: $height, $width: $width,$marginBottom: $marginBottom, $paddingBottom: $paddingBottom);
  text-align: center;

  &:after {
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
  }
}

@mixin withBorderBottomLeft($borderColor: $color-4, $height: 4px, $width: 40px, $marginBottom: $defaultMarginY, $paddingBottom: $defaultPaddingY) {
  @include withBorderBottom($borderColor: $borderColor, $height: $height, $width: $width,$marginBottom: $marginBottom, $paddingBottom: $paddingBottom);

  &:after {
      left: 0;
  }
}

@mixin withBorderRight ($borderColor: $color-2, $borderWidth: 3px, $height: 40px, $marginRight: 15px, $paddingRight: 15px) {
    // Reset
    margin: 0;
    padding: 0;

    margin-right: $marginRight;
    padding-right: $paddingRight;
    position:relative;

    &:after {
        // Reset
        border: none;

        border-right: $borderWidth solid $borderColor;
        content: " ";
        position: absolute;
        right: 0;
        height: $height;
    }
}
