.contact-form {
  label {
    font-weight: 400;
    margin-top: 10px;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="radio"],
  textarea {
    background-color: $wColor;
    border: none;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    width: 100%
  }


  textarea {
    resize: none;
  }

  &__notice {
    font-size: $smallFontSize;
  }

  &__buttons {
    text-align: right;

    &__button {
      width: 120px;

      @include defaultButton($marginY: $defaultMarginY);
      @include buttonColor($color-5: $wColor, $backgroundColor: $color-3, $borderColor: $color-3);

      @include defaultTransition();
    }
  }

  // Override BS focus
  .form-control:focus {
    box-shadow: 0 0 2px 2px $color-2 //rgba(148, 142, 142, 0.5);
  }
}
