.sidebar {
  background-color: $color-1;
  color: $wColor;
  float: left;
  font-size: 0.875em; //14px
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: $defaultPaddingY;
  padding-top: 90px;
  position: relative;
  width: 320px;

  @include md {
    font-size: $normalFontSize;
    padding-top: 120px;
    width: 360px;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  a:hover,
  a:focus {
    text-decoration: none;
  }

  &__logo {
    text-align: center;
    margin-bottom: $defaultMarginY;

    img {
      width: 50px;
    }
  }

  &__primary-nav {
    color: $wColor;
    font-weight: 600;
    margin-bottom: $defaultMarginY;
    text-transform: uppercase;

    li {
      margin: 5px 0;
      padding-bottom: 5px;
      padding-left: $defaultPaddingX;
      padding-right: $defaultPaddingX;
      padding-top: 5px;

      &:hover {
        background-color: #000000;
      }

      a {
        display: block;
        cursor: pointer;
      }
    }

  }


  &__footer {
    padding-left: $defaultPaddingX;
    padding-right: $defaultPaddingX;

    &--social-nav {
      color: $wColor;
      font-size: $mediumFontSize;

      li {
        display: inline-block;
        margin-right: 10px;

        &:last-child { margin-right: 0;}
      }
    }

    &--footer-nav {
      color: $wColor;
      margin-bottom: $defaultMarginY;

      li {
        margin: 5px 0;
      }
    }
  }

}
