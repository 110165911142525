$defaultEffectDuration: 400ms;

@mixin defaultTransition() {
    @include transition(all, $defaultEffectDuration);
}

@mixin transition($arguments...) {
    -webkit-transition: $arguments;
    -moz-transition: $arguments;
    -o-transition: $arguments;
    transition: $arguments;
}
