.hero {
  position: relative;
  padding-top: 100px;

  @include lg {
    padding-top: 150px;
  }

  &__intro {
    color: $color-3;

    @include md {
      h1 { font-size: $bigFontSize; }

      h2 { font-size: $xLargeFontSize; }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('../img/logo_alt.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0.06;
      z-index: -1;
    }

    strong {
      font-size: $largeFontSize;
      @include md {
        font-size: $xLargeFontSize;
      }
    }

    p {
      font-weight: bold;
      padding-left: $mediumMarginX;
    }

    &--title1 {
      font-family: $defaultFontFamily;
      font-weight: 600;
    }

    &--title2 {
      color:  $color-1;
    }
  }

  &--small {
    height: 40vh;
  }

  &__slider {
    .owl-stage-outer,
    .owl-stage {
      height: 100%;
    }

    .owl-item {
      height: 100%;
      margin: 0;
      padding: 0;
    }

    &--slide {
      &--text {
        // padding-top: 50%;
        // transform: translateY(-25%);
        // padding-left: 10%;
        // padding-right: 10%;
        h1 {
          margin: 0;
        }
        @include md {
          h1 { font-size: $xLargeFontSize; }
        }
        h2 {
          color: $color-3;
          font-size: $mediumFontSize;
          @include md {
            font-size: $largeFontSize;
          }
        }
        p {
          color: $color-3;
          a:hover {
            text-decoration: underline !important;
          }
        }
      }

      &--icons {
        margin-bottom: 20px;
        h2 {
          color: $color-3;
          font-size: $mediumFontSize;
          @include md {
            font-size: $largeFontSize;
          }
        }
      }

      li {
        list-style-type: none;
      }

      svg  {
        font-weight: 900;
        font-size: 0.8em;
        margin-right: 10px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        color: $color-3;
      }

      .icon-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 140px;
        margin: 10px;
      }

      .icon-hero {
        width: 70px;
        height: 70px;
        object-fit: cover;
        border-radius: 50%;
        margin-bottom: 10px;

        img {
          width: 80px;
          height: 80px;
          object-fit: cover;
          border-radius: 50%;
          margin-bottom: 10px;
        }
      }

      .icon-label {
        text-align: center;
        font-size: 14px;
        color: $color-3;
      }

      .icon-list{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: baseline;
        @include md {
          flex-wrap: nowrap;
        }

      }
    }
  }

  &__slider-custom-nav {
    color: $color-1;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top: 45%;
    z-index: 5;

    &--slider-nav-left {
      left: 15px;
    }

    &--slider-nav-right {
      right: 15px;
    }

    &--disabled {
      display: none;
    }
  }

  &__scroll-to-top-container {
    text-align: center;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;

    &--icon-button {
      @include iconRoundButton();
      @include buttonColor($color-5: $color-3, $backgroundColor: transparent, $borderColor: $color-3, $emBackgroundColor: $color-3, $emBorderColor: $color-3);
      @include defaultTransition();
    }
  }
}
