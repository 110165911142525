.home {
  &__what {
    background-color: $color-3;
    h2 {
      color:$color-1;
      font-size: $largeFontSize;
      margin-bottom: $defaultMarginY;
      @include md {
        font-size: $xLargeFontSize;
        margin-bottom: $defaultMarginY * 2;
      }
      @include withBorderBottomCenter();

    }
    ul {
      list-style-type: none;
      padding-left : 0;

      @include md {
        padding-left : $mediumPaddingX;
      }
    }

      li {
        svg {
          margin-right: 15px;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          color: $color-1;
          font-weight: 900;
          font-size: inherit;
        }
      }
  }

  &__how {
    h2 {
      font-size: $largeFontSize;
      margin-bottom: $defaultMarginY;
      @include md {
        font-size: $xLargeFontSize;
        margin-bottom: $defaultMarginY * 2;
      }
      @include withBorderBottomCenter();

    }

    ul {
      list-style-type: none;
      padding-left : 0;

      @include md {
        padding-left : $mediumPaddingX;
      }

      // we don't use Font Awesome 5 Free anymore
      // li {
      //   &::before {
      //     content:'\f105';
      //     display: inline-block;
      //     font-family: "Font Awesome 5 Free";
      //     font-weight: 900;
      //     font-size: inherit;
      //     margin-right: 20px;
      //     text-rendering: auto;
      //     -webkit-font-smoothing: antialiased;
      //     color: $color-3;
      //   }
      // }
    }

    &--download_button{
      margin-top: $defaultMarginY;
    }

  &__carrousel {
    position: relative;
    margin-top: $defaultMarginY;
    background-color: rgba($color-2, 0.3);
  }
  &__slider {
    padding-left: $defaultPaddingX;
    padding-right: $defaultPaddingX;
    padding-top: $smallPaddingY;
    padding-bottom: $smallPaddingY;

    .owl-stage-outer,
    .owl-stage {
      height: 100%;
    }

    .owl-item {
      height: 100%;
      margin: 0;
      padding: 0;
    }
    &--slide {
      // we don't use Font Awesome 5 Free anymore
      // h2::before {
      //   content:'\f111';
      //   display: inline-block;
      //   font-family: "Font Awesome 5 Free";
      //   font-weight:800;
      //   font-size: 15px;
      //   margin-right: 20px;
      //   vertical-align : middle;
      //   text-rendering: auto;
      //   -webkit-font-smoothing: antialiased;
      //   color: $color-3;
      // }
    }
  }
  &__slider-custom-nav {
    color: $color-1;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top: 40%;
    z-index: 5;

    &--slider-nav-left {
      left: -15px;
    }

    &--slider-nav-right {
      right: -15px;
    }

    &--disabled {
      display: none;
    }
  }
}
}
